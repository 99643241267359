//

export function isSICategory(cat_id) {
    return [
        'insurance',
        'tech_partner',
        'platforms',
        'logistics_services',
        'solutions_integrator',
    ].includes(cat_id)
}

export function getAccountNumber(carrier) {
    if (carrier.carrier_type === 'ups') {
        return carrier.shipper_number
    } else {
        return carrier.carrier_user_id
    }
}
