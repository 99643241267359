import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { showShipStationWizard } from 'reducers/modules/integrations'
import { getRailsRoutes } from 'utils/railsRoutes'
import useGetShipStation from './useGetShipStation'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'
import useLogos from 'hooks/useLogos'

export function ShipStation({ isRecommended = true, canInstall = true }) {
    const logos = useLogos(false)
    const routes = getRailsRoutes()
    const dispatch = useDispatch()
    const { shipStation, isGettingShipStation } = useGetShipStation()

    const handleInstall = () => dispatch(showShipStationWizard())

    return (
        <div
            data-test-url={routes.ratesmgr_marketplace_path('shipStation')}
            className={`${isRecommended ? 'app-1' : 'app-2'}${
                logos?.[`${shipStation?.name}ComingSoon`] ? ' coming-soon' : ''
            } ${canInstall ? '' : 'carrier-disabled'}`}
        >
            <div>
                {!canInstall && <div className="lock-icon-holder"><div className="lock-icon"><div className='fas fa-lock'></div></div></div>}
                <Link
                    to={routes.ratesmgr_marketplace_path('shipStation')}
                    className="logo shipStation"
                    style={{ backgroundColor: 'rgb(27 39 44)' }}
                    >
                    <LogoImg
                        src={logos?.[shipStation?.name]}
                        alt={shipStation?.title}
                        />
                </Link>
            </div>

            <div className="info">
                <div className="title">
                    <h4>ShipStation</h4>
                    <p className="type">Technology Partner</p>
                    <p className="desc"></p>
                </div>
                {!isGettingShipStation && (
                    (!shipStation?.installed && !canInstall) ?
                    <UpgradePlanButton />
                    :
                    <Status
                        installed={shipStation?.installed}
                        onInstallClick={handleInstall}
                    />
                )}
            </div>
        </div>
    )
}

function UpgradePlanButton() {
    return (
        <div className="status">
            <button
                className="btn btn-sm btn-primary"
            >
                Upgrade to install
            </button>
        </div>
    )
}

function Status({ installed, onInstallClick }) {
    return (
        <div className="status">
            {installed ? (
                <button
                    className="btn btn-sm btn-success"
                    title="Install"
                >
                    <i className="fas fa-check-circle" /> Installed
                </button>
            ) : (
                <button
                    className="btn btn-sm btn-basic"
                    title="Install"
                    onClick={onInstallClick}
                >
                    Install
                </button>
            )}
        </div>
    )
}

export default ShipStation
