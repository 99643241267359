import React from 'react'

export const InstallView = ({ logo, onContinue, onCancel }) => {
    return (
        <>
            <div className="center">
                <h3 className="normal">Installing Zonos</h3>
            </div>

            {logo && (
                <div className="c-message">
                    <div className="c-named">
                        <div className="carrier-selected carrier-name-reTransFreight c-type-live">
                            <img
                                src={logo}
                                title={`zonos-title`}
                                alt={`zonos-title`}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div
                className="reqs-list center"
                style={logo ? {} : { marginTop: 0 }}
            >
                <div className="mb-5 center">
                    <h4 className="normal">
                        <strong>
                            Enter your credentials to start calculating duties
                            and taxes
                        </strong>
                        <br />
                        The credentials you will need are:
                    </h4>
                </div>
                <i className="fas fa-check info mr-4" />
                Zonos API Key
                <div className="divider mt-9 mb-9" />
                <a
                    href={
                        'https://docs.shipperhq.com/how-to-connect-shipperhq-to-zonos'
                    }
                    target="_blank"
                    className="info-link"
                    onClick={(e) => e.stopPropagation()}
                >
                    <i className="far fa-info-circle" /> How to obtain your
                    Zonos API Key
                </a>
            </div>

            <div className="center">
                <button
                    data-testid="agree-step-install-button"
                    type="button"
                    className="btn btn-primary"
                    onClick={onContinue}
                >
                    Enter Credentials
                </button>

                {onCancel && (
                    <button
                        className="link sub-action mt-10"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                )}
            </div>
        </>
    )
}
