//
import React, { Component } from 'react'
import breadCrumbs from 'utils/breadCrumbs'
import { connect } from 'react-redux'

import railsRoutes from 'utils/railsRoutes'
import { withRouter } from 'react-router'
import {
    endCarrierConfiguration,
    installCarrier,
    configureCarrier,
    markValidated,
} from 'reducers/modules/integrations'
import CarrierDetailsView from './CarrierDetails'
import { reloadAppData, showNotification } from 'reducers/modules/app'
import { AccountValidator } from '../../AccountValidator'
import InstallStatus from 'components/dashboard/integrations/containers/Details/components/InstallStatus'
import ValidationStatus from 'components/dashboard/integrations/containers/Index/components/ValidationStatus'
import useCarrierTitle from 'components/dashboard/integrations/containers/CarrierWizard/hooks/useCarrierTitle'
import { getAccountNumber } from 'components/dashboard/integrations/utils'
import ValidationStatusContext from 'components/dashboard/integrations/components/ValidationStatusContext'
import IndependentSetup from 'components/dashboard/carriers/components/IndependentSetup'
import Loader from 'components/Loader'
import AccountChooser from 'components/dashboard/integrations/containers/Details/components/AccountChooser'
import ListRatesBanner from 'components/dashboard/integrations/components/ListRatesBanner'
import logger from 'utils/logger'
import CarrierDetailsActions from './CarrierDetailsActions'
import { useRailsRoutes } from 'utils/railsRoutes'

class CarrierDetailsPage extends Component {
    installHandler = () => {}

    constructor(props) {
        super(props)

        const account = this.selectAccount()

        this.state = {
            validated: !!account?.validated,
            selectedAccount: account,
            independentSetupVisible: false,
            replaceCredentials: false,
        }
    }

    selectAccount() {
        const { carrier } = this.props
        if (!carrier?.accounts) {
            return {
                validated: false,
            }
        }

        try {
            const params = new URLSearchParams(this.props.location.search)
            const independentId = parseInt(params.get('independent'))

            let account =
                independentId &&
                carrier.accounts.find((acc) => acc.id === independentId)

            if (account) {
                return account
            }

            account = carrier.accounts.find((acc) => acc.owner)

            if (account) {
                return account
            }
        } catch (e) {
            logger.error('error parsing params', e)
        }

        return carrier.accounts[0]
    }

    /**
     * Used by AccountChooser
     * @param id
     */
    onAccountSelected = (id) => {
        const { carrier } = this.props
        const idParsed = parseInt(id)
        const selectedAccount = carrier.accounts.find(
            (acc) => acc.id === idParsed
        )

        if (selectedAccount) {
            this.setState({
                selectedAccount,
                validated: selectedAccount.validated,
            })

            try {
                const {
                    history: {
                        location: { pathname },
                        push,
                    },
                } = this.props

                push(`${pathname}?independent=${selectedAccount.id}`)
            } catch (e) {
                console.error(e)
            }
        }
    }

    /**
     * Handle scope changes
     * @param prevProps
     * @param prevState
     */
    async componentDidUpdate(prevProps, prevState) {
        if (
            (!prevProps.carrier?.accounts && this.props.carrier?.accounts) ||
            !this.state.selectedAccount ||
            !this.state.selectedAccount.id
        ) {
            const account = this.selectAccount()

            if (account && Object.keys(account).length > 1) {
                this.setState({
                    validated: !!account?.validated,
                    selectedAccount: account,
                })

                this.props.pushBreadcrumb({
                    title: this.props.carrier.title,
                })
            }
        }

        if (prevProps.scope !== this.props.scope) {
            await this.props.reloadAppData(false)
        }
    }

    addAccount = () => {
        const { carrier } = this.props
        /* $FlowFixMe */
        this.props.configureCarrier({
            name: carrier.name,
        })
    }

    replaceCredentials = (e) => {
        e.preventDefault()

        this.setState({
            replaceCredentials: true,
        })
    }

    addAnother = () => {
        this.setState({
            independentSetupVisible: true,
        })
    }

    /**
     * This is called from the Carriers page
     */
    independentSetupClosed = () => {
        this.setState({
            independentSetupVisible: false,
            replaceCredentials: false,
        })

        // reload data so new account will be displayed in the selector
        this.props.reloadAppData(false)
    }

    render() {
        const { selectedAccount, independentSetupVisible, replaceCredentials } =
            this.state
        const { carrier, form, enabledFeatures, canInstall } = this.props
        /* $FlowFixMe */
        const containsDimship = enabledFeatures?.includes('dimship')
        let canInstallCarrier = true

        const account = selectedAccount

        if (!carrier) {
            return <Loader />
        }

        if (carrier.name === 'uShip' && !containsDimship) {
            canInstallCarrier = false
        }

        return (
            <div className="carrier-details max-cont-l">
                <div className="main-info">
                    {form && form.is_slow ? (
                        <div className="fail-msg">
                            <i className="fa fa-exclamation-circle ico-warning" />{' '}
                            ShipperHQ does not recommend the use of this carrier
                            due to its very slow performance in returning rates
                        </div>
                    ) : null}

                    <div className="c-title">
                        <h2>{carrier.title}</h2>

                        <div className="status">
                            <InstallStatus
                                carrier={account}
                                disabled={!canInstallCarrier}
                                onInstall={() => {
                                    this.installHandler()
                                }}
                            />
                            <ValidationStatus carrier={account} small={true} />
                        </div>
                    </div>

                    <hr />

                    <AccountChooser
                        onChange={this.onAccountSelected}
                        onInstall={this.addAccount}
                        accounts={carrier.accounts}
                        selected={account?.id || null}
                    />

                    {(account?.validated || account?.list_rates) &&
                    !replaceCredentials ? (
                        <ValidatedNote
                            carrier={carrier}
                            form={form}
                            canInstall={canInstall}
                            account={selectedAccount}
                            replaceCredentials={this.replaceCredentials}
                            addAnother={this.addAnother}
                        />
                    ) : (
                        <ValidationStatusContext.Provider
                            value={{
                                setValidated: (validated) =>
                                    this.setState({ validated }),
                                setInstalled: async () => {
                                    try {
                                        await this.props.reloadAppData(false)

                                        const updatedData =
                                            this.props.carrier.accounts.find(
                                                (account) =>
                                                    account.id ===
                                                    selectedAccount.id
                                            )

                                        if (updatedData) {
                                            this.setState({
                                                selectedAccount: updatedData,
                                            })
                                        }
                                    } catch (e) {
                                        logger.error(e)
                                    }
                                },
                                setInstallHandler: (installHandler) => {
                                    this.installHandler = installHandler
                                },
                                replaceCredentials,
                            }}
                        >
                            <div className="enter-creds">
                                <AccountValidator
                                    carrier={carrier}
                                    account={account}
                                    onInstall={this.props.installCarrier}
                                    onClose={() => {
                                        this.setState({
                                            replaceCredentials: false,
                                        })
                                    }}
                                />
                            </div>
                        </ValidationStatusContext.Provider>
                    )}

                    {independentSetupVisible && (
                        <IndependentSetup
                            skipAppDataLoading={true}
                            carrier={{
                                ...carrier,
                                type: carrier.name,
                            }}
                            onClose={this.independentSetupClosed}
                        />
                    )}
                </div>
                <CarrierDetailsView form={form} name={carrier.name} />
            </div>
        )
    }
}

export default withRouter(
    connect(
        ({
            app: {
                scope,
                option_sources,
                enabled_features = [],
                integrations: {
                    selected_carrier,
                    account_form,
                    configure,
                    can_install,
                } = {},
            },
        }) => ({
            carrier: selected_carrier,
            form: account_form,
            optionSources: option_sources,
            scope,
            configure,
            canInstall: can_install,
            enabledFeatures: enabled_features,
        }),
        {
            installCarrier,
            endCarrierConfiguration,
            configureCarrier,
            showNotification,
            markValidated,
            reloadAppData,
        }
    )(
        breadCrumbs(
            [
                {
                    title: 'Marketplace',
                    route: 'ratesmgr_marketplace_index_path',
                },
            ],
            {
                setOnMount: false,
            }
        )(railsRoutes(CarrierDetailsPage))
    )
)

function ValidatedNote(props) {
    const {
        carrier,
        form: { auto_install } = {},
        canInstall,
        account,
        replaceCredentials,
        addAnother,
    } = props
    const routes = useRailsRoutes()
    const carrierTitle = useCarrierTitle(carrier.name)

    if (auto_install) {
        return (
            <>
                <div className="validate-result">
                    <div className="success">
                        <p>{carrierTitle} is Successfully Installed</p>
                    </div>
                </div>
            </>
        )
    }

    if (account?.list_rates) {
        return (
            <ListRatesBanner
                onPrimaryActionClick={replaceCredentials}
                carrierType={account?.carrier_type}
            />
        )
    }

    return (
        <>
            <CarrierDetailsBody
                carrier={carrier}
                account={account}
                replaceCredentials={replaceCredentials}
                routes={routes}
                carrierTitle={carrierTitle}
            />

            <CarrierDetailsActions
                addAnother={addAnother}
                canInstall={canInstall}
                carrier={carrier}
            />
        </>
    )
}

const CarrierDetailsBody = ({
    carrier,
    account,
    replaceCredentials,
    routes,
    carrierTitle,
}) => {
    if (account?.list_rates) {
        return (
            <ListRatesBanner
                onPrimaryActionClick={replaceCredentials}
                carrierType={account?.carrier_type}
            />
        )
    }

    if (account?.required_oauth_migration) {
        return (
            <div className="validation-notice">
                <i className="fas fa-shipping-fast ico-caution" />{' '}
                {`To continue receiving your ${carrierTitle} account rates`}{' '}
                <a
                    href={`${routes.edit_ratesmgr_carrier_path(account.id)}#credentials`}
                >
                    Update Credentials
                </a>
            </div>
        )
    }

    return (
        <div className="validate-result">
            <div className="success">
                <p>
                    {carrierTitle} account{' '}
                    <strong>{getAccountNumber(account)}</strong> is successfully
                    validated
                </p>
                <a href="#" className="sub-link" onClick={replaceCredentials}>
                    {carrier.carrier_type === 'ups'
                        ? 'Update Credentials'
                        : 'View and Update Credentials'}
                </a>
            </div>
        </div>
    )
}
