import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getRailsRoutes } from 'utils/railsRoutes'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'
import { useIntegration } from '../../hooks/useInstallIntegration'
import { InstallZonosModal } from './Install/Modal'
import { dataCache } from 'reducers/storeCache'
import { useIsFrontend, useIsLoggedIn } from 'hooks/frontend'

export const ZonosEntry = ({ isRecommended = true, carrier }) => {
    const [installModal, setInstallModal] = useState(false)
    const toggleModal = () => setInstallModal((prev) => !prev)
    const isFrontend = useIsFrontend()
    const loggedIn = useIsLoggedIn()

    const logos = useSelector(({ app }) => app?.integrations?.logos)
    const routes = getRailsRoutes()

    const {
        data: { installed, ...zonosData } = {},
        isLoading,
        refetch,
    } = useIntegration({ type: 'zonos', key: 'zonos' }, { skip: !loggedIn })

    const handleRefresh = () => {
        refetch()
        dataCache.flush()
    }

    return (
        <>
            <div
                data-test-url={routes.ratesmgr_marketplace_path('zonos')}
                className={`${isRecommended ? 'app-1' : 'app-2'}${
                    logos?.[`${carrier?.name}ComingSoon`] ? ' coming-soon' : ''
                }`}
            >
                <Link
                    to={routes.ratesmgr_marketplace_path('zonos')}
                    className="logo"
                    style={{ backgroundColor: logos?.zonosBg }}
                >
                    <LogoImg
                        src={logos?.[carrier?.name]}
                        alt={carrier?.title}
                    />
                </Link>
                <div className="info">
                    <div className="title">
                        <h4>{carrier?.title}</h4>
                        <p className="type">{carrier?.category}</p>
                    </div>

                    {!isLoading && !isFrontend && (
                        <div className="status">
                            {installed ? (
                                <div className="installed">
                                    Installed <i className="fas fa-check" />
                                </div>
                            ) : (
                                <button
                                    className="btn btn-sm btn-basic"
                                    title="Install"
                                    onClick={toggleModal}
                                >
                                    Install
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {installModal && (
                <InstallZonosModal
                    installView
                    integration={zonosData}
                    onInstall={handleRefresh}
                    onClose={toggleModal}
                />
            )}
        </>
    )
}
