import { useState } from 'react'

export default function useSteps() {
    const [currentStep, setCurrentStep] = useState(1)
    const goToNextStep = () => {
        setCurrentStep((step) => step + 1)
    }

    const goToBackStep =
        currentStep === 1 ? null : () => setCurrentStep((step) => step - 1)

    return {
        currentStep,
        goToNextStep,
        setCurrentStep,
        goToBackStep,
    }
}
