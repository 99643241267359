//
import React, { Component } from 'react'
import breadCrumbs from 'utils/breadCrumbs'
import CarrierGrid from './components/CarrierGrid'
import Ad from './components/Ad'
import RecommendedCarriers from './components/RecommendedCarriers'
import SearchBar from './components/SearchBar'
import { connect } from 'react-redux'
import CarrierWizard from '../CarrierWizard'
import SearchContext from 'components/ui/SearchProvider'
import {
    installCarrier,
    endCarrierConfiguration,
} from 'reducers/modules/integrations'
import { reloadAppData } from 'reducers/modules/app'
import { categoryMatches } from './components/categories'
import { ShipStationWizard } from 'components/dashboard/integrations/services/ShipStation/ShipStationWizard'
import { ModalProvider } from 'components/ui/ModalProvider'
import { IntegrationModals } from 'components/dashboard/integrations/modals'
import { StatusSelector } from './components/StatusSelector'
import styled from 'styled-components'
import AwardIcon from 'assets/images/ratesmgr/icons/icon-award.svg'
import { CustomCarrierBanner } from '../../components/CustomCarrierBanner'

export const STORAGE_KEY = 'integrations-search'

function reloadAndSearch(prevProps, props, state) {
    if (prevProps.scope !== props.scope) {
        props.reloadAppData(false)

        state.search()
    } else if (
        prevProps.recommended_carriers &&
        props.recommended_carriers &&
        prevProps.recommended_carriers.length !==
        props.recommended_carriers.length
    ) {
        state.search()
    }
}

function saveSearchToSessionStorage(searchQ, prevState) {
    if (
        searchQ &&
        prevState.searchQ &&
        (searchQ.category !== prevState.searchQ.category ||
            searchQ.status !== prevState.searchQ.status ||
            searchQ.location !== prevState.searchQ.location)
    ) {
        sessionStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                category: searchQ.category,
                status: searchQ.status,
                location: searchQ.location,
            })
        )
    }
}

function createInitialSearchQ(
    // These values will be selected by default in the Marketplace search/filter bar
    category = 'all',
    status = 'all',
    location = 'all'
) {
    return {
        query: '',
        category,
        status,
        location,
    }
}

function saveSearchCategoryToSessionStorage(props, location) {
    let persistedOptions = JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || {}

    if (props?.selected_category) {
        persistedOptions.category = props.selected_category
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(persistedOptions))
    } else if (location?.hash?.length > 0) {
        persistedOptions.category = location.hash.substr(1)
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(persistedOptions))
    }
}

class Index extends Component {
    searchCB = searchCarriers

    /**
     * Handle scope changes
     * @param props
     * @param state
     */
    componentDidUpdate(prevProps, prevState) {
        reloadAndSearch(prevProps, this.props, this.state)
        saveSearchToSessionStorage(this.state.searchQ, prevState)
    }

    handleScroll = () => {
        window._mpScrollPosition = window.scrollY
    }

    componentDidMount() {
        saveSearchCategoryToSessionStorage(this.props, location)

        if (this.props.frontend) {
            // save & restore scroll position for FF
            const browser = navigator.userAgent.toLowerCase()
            if (browser.indexOf('firefox') > -1) {
                const currentPosition = window._mpScrollPosition
                setTimeout(() => {
                    if (window._mpScrollPosition) {
                        window.scrollTo(0, currentPosition)
                    }
                }, 0)
                window.addEventListener('scroll', this.handleScroll)
            }
        }
    }

    componentWillUnmount() {
        if (this.props.frontend) {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

    constructor(props) {
        super(props)

        const setSearchQuery = (query) => {
            const searchQ = {
                query: query.query.trim().toLowerCase(),
                category: query.category,
                status: query.status,
                location: query.location,
            }
            this.setState({
                searchQ,
                ...getResults(this.props, searchQ),
            })
        }

        const setSearchFunction = (callback) => {
            this.searchCB = callback
        }

        const search = () => {
            const { searchQ } = this.state

            this.setState({
                ...getResults(this.props, searchQ),
            })
        }

        const getInitialCategory = (props, location) => {
            if (props.selected_category) {
                return props.selected_category
            } else if (location.hash?.length > 0) {
                return location.hash.substr(1)
            } else {
                return JSON.parse(sessionStorage.getItem(STORAGE_KEY))?.category
            }
        }

        const initialStatus = JSON.parse(
            sessionStorage.getItem(STORAGE_KEY)
        )?.status

        this.state = {
            searchQ: createInitialSearchQ(
                getInitialCategory(props, location),
                initialStatus,
                props.location
            ),
            setSearchQuery,
            setSearchFunction,
            search,
        }
    }

    render() {
        const {
            configure,
            installCarrier,
            endCarrierConfiguration,
            isShipStationWizardShowing,
        } = this.props

        const { searchQ } = this.state
        const allCarriersAndCategories = {
            recommended_carriers: this.props.recommended_carriers || [],
            available_integrations: this.props.available_integrations || [],
            carrier_categories: this.props.carrier_categories || [],
        }
        const { recommended_result, available_result } = getResults(
            allCarriersAndCategories,
            searchQ
        )

        let recommendedCarriers = [...recommended_result]
        let remainingCarriers = [...available_result]

        const emptyResult =
            !recommendedCarriers.length && !remainingCarriers.length

        if (this.state.searchQ?.query?.length) {
            // when search is active - show the carrier only in recommended list
            // (if it's recommended)
            const recommendedMap = {}
            for (const carrier of recommendedCarriers) {
                recommendedMap[carrier.name] = true
            }

            remainingCarriers = remainingCarriers.filter(
                (carrier) => !recommendedMap[carrier.name]
            )
        }

        const onStatusChange = (e) => {
            const searchQ = {
                query:
                    JSON.parse(sessionStorage.getItem(STORAGE_KEY))?.query ||
                    '',
                category:
                    JSON.parse(sessionStorage.getItem(STORAGE_KEY))?.category ||
                    'all',
                status: e.target.value,
                location:
                    JSON.parse(sessionStorage.getItem(STORAGE_KEY))?.location ||
                    'all',
            }

            this.setState({ searchQ })
        }

        return (
            <ModalProvider modalMap={IntegrationModals}>
                <SearchContext.Provider value={this.state}>
                    <div className="page-header">
                        <SearchBar query={searchQ} />
                    </div>

                    <div className="bottom-main">
                        <ReccomendedHeader className="max-cont">
                            {recommendedCarriers.length > 0 && (
                                <Title>
                                    <img src={AwardIcon} alt="" />{' '}
                                    <h3>Recommended by ShipperHQ</h3>
                                </Title>
                            )}

                            <EmptyResult empty={emptyResult}>
                                {emptyResult && (
                                    <div>
                                        <EmptyHeader>
                                            There are no results for “
                                            {searchQ.query || searchQ.category}”
                                        </EmptyHeader>
                                        <p>
                                            Remove any filters or categories you
                                            have applied. Otherwise, try a new
                                            search term.
                                        </p>
                                    </div>
                                )}


                                {this.state.searchQ?.category !== 'other' &&
                                    !this.props.frontend && (
                                        <StatusSelector
                                            status={
                                                this.state.searchQ?.status ||
                                                'all'
                                            }
                                            onStatusChange={onStatusChange}
                                        />
                                    )}
                            </EmptyResult>
                        </ReccomendedHeader>

                        {emptyResult && <CustomCarrierBanner />}

                        <div className="max-cont">
                            <RecommendedCarriers
                                category={searchQ.category}
                                carriers={recommendedCarriers}
                            />
                        </div>

                        <Ad />

                        <CarrierGrid
                            carriers={remainingCarriers}
                            category={searchQ.category}
                        />
                        {!emptyResult && !remainingCarriers.length && (
                            <div className="bottom-main">
                                <div className="max-cont" />
                            </div>
                        )}

                        {configure && (
                            <CarrierWizard
                                carrier={configure}
                                onInstall={installCarrier}
                                onClose={endCarrierConfiguration}
                            />
                        )}
                        {isShipStationWizardShowing && <ShipStationWizard />}
                    </div>
                </SearchContext.Provider>
            </ModalProvider>
        )
    }
}

// $FlowFixMe
export default connect(
    ({
        app: {
            scope,
            frontend,
            integrations: {
                recommended_carriers,
                available_integrations,
                configure,
                carrier_categories,
                location,
                selected_category,
                isShipStationWizardShowing,
            } = {},
        } = {},
    }) => ({
        frontend,
        scope,
        recommended_carriers,
        available_integrations,
        configure,
        carrier_categories,
        location,
        selected_category,
        isShipStationWizardShowing,
    }),
    {
        installCarrier,
        endCarrierConfiguration,
        reloadAppData,
    }
)(breadCrumbs([{ title: 'Marketplace' }])(Index))

function searchCarriers(query, data, secondaryCategories = {}) {
    return (
        data?.filter((carrier) => {
            if (
                query.query &&
                query.query.length > 0 &&
                !carrier.title.toLowerCase().includes(query.query)
            ) {
                return false
            }

            if (
                query.category &&
                query.category.length > 0 &&
                query.category !== 'all' &&
                !categoryMatches(carrier, query.category, secondaryCategories)
            ) {
                return false
            }

            if (
                query.status &&
                query.status.length > 0 &&
                query.status !== 'all'
            ) {
                // display installed carriers or not-install carriers regardless of validation
                return (
                    (query.status === 'installed' && carrier.installed) ||
                    (query.status === 'not-installed' && !carrier.installed)
                )
            }

            return true
        }) || []
    )
}

function getResults(props, searchQ) {
    const recommendedResult = searchCarriers(
        searchQ,
        props.recommended_carriers,
        props.carrier_categories
    )

    const recommendedNames = recommendedResult.map((item) => item.name)
    let available_result = searchCarriers(
        searchQ,
        props.available_integrations,
        props.carrier_categories
    )

    if (
        searchQ &&
        ((searchQ.query && searchQ.query.length) ||
            (searchQ.category !== 'all' && searchQ.category))
    ) {
        available_result = available_result.filter((item) => {
            return !recommendedNames.includes(item.name)
        })
    }

    return {
        recommended_result: recommendedResult,
        available_result,
    }
}

const ReccomendedHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
    margin: 0 auto;
`

const Title = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 12px;
    }

    h3 {
        margin: 0;
        font-weight: 700;
        color: #333;
        font-size: 22px;
    }
`

const EmptyResult = styled.div`
    display: flex;
    width: ${(props) => (props.empty ? '100%' : 'auto')};
    align-items: start;
    justify-content: space-between;
    margin-left: auto;
`

const EmptyHeader = styled.h4`
    font-weight: 700;
    font-size: 22px;
    margin: 0;
`
