import React from 'react'
import { Form, Field } from 'react-final-form'
import { fieldInput } from 'components/ui/form/renderers'

export const Credentials = ({ onSubmit, onCancel, loading }) => {
    return (
        <>
            <div className="center">
                <h3 className="normal">Installing Zonos</h3>
            </div>

            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="center">
                            <p className="normal">
                                <strong>Zonos Credentials</strong>
                            </p>
                        </div>
                        <div className="row xsmini-row flex-center left mb-5">
                            <Field
                                title="API Key"
                                validate={(value) =>
                                    value ? undefined : 'Required'
                                }
                                name="api_key"
                                component={fieldInput}
                                fullWidth={true}
                                className="col-md-6 xsmini-col"
                            />
                        </div>
                        <div>
                            <button
                                onClick={handleSubmit}
                                className="btn btn-primary"
                                disabled={loading}
                                type={'button'}
                            >
                                Install
                            </button>

                            {onCancel && (
                                <button
                                    href="#"
                                    disabled={loading}
                                    className="link sub-action"
                                    onClick={onCancel}
                                    style={{ marginBottom: 0 }}
                                >
                                    Cancel
                                </button>
                            )}
                        </div>{' '}
                    </form>
                )}
            />
        </>
    )
}
