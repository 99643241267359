import React from 'react'

export const StatusSelector = ({ status, onStatusChange }) => {
    return (
        <div className="status-filter">
            <label>Filter:</label>
            <div className="select-sm">
                <select
                    onChange={(e) => onStatusChange(e)}
                    className="form-control input-sm"
                    defaultValue={status || 'all'}
                >
                    <option value="all" selected={status === 'all'}>
                        Installed & Uninstalled
                    </option>
                    <option value="installed" selected={status === 'installed'}>
                        Installed
                    </option>
                    <option
                        value="not-installed"
                        selected={status === 'not-installed'}
                    >
                        Uninstalled
                    </option>
                </select>
            </div>
        </div>
    )
}
