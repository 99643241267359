//
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoStyle } from 'components/dashboard/integrations/containers/Index/components/utils'
import { isSICategory } from 'components/dashboard/integrations/utils'
import { Helmet } from 'react-helmet'
import history from 'components/frontend/history'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'

/**
 * FrontendDetailsPage Properties
 */

export const FrontendDetailsPage = ({
    logged_in,
    carrier,
    logos,
    carrier: {
        name,
        title,
        image,
        summary,
        description,
        cta,
        link_url,
        link_text,
        is_freight,
        meta: { title: metaTitle, description: metaDescription } = {},
    } = {},
}) =>
    carrier ? (
        <Fragment>
            {metaTitle && (
                <Helmet>
                    <title>Marketplace | {metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                </Helmet>
            )}
            <div className="container-fluid lt-blue-bg sect-pad-xl">
                <div className="carrier-details paper-bg">
                    <div
                        className="color-nav"
                        {...logoStyle(logos, name, 'BgAlt')}
                    >
                        <Link
                            to="/marketplace"
                            className="btn btn-sm btn-lt-wt"
                            onClick={(e) => {
                                e.preventDefault()
                                history.goToOrBack('/marketplace')
                                return false
                            }}
                        >
                            <i className="far fa-arrow-left" /> Marketplace
                        </Link>
                    </div>
                    <div className="dt-cont clearfix">
                        <div
                            className={
                                'dt-img' +
                                (logos && logos[name + 'ComingSoon']
                                    ? ' coming-soon'
                                    : '')
                            }
                        >
                            <LogoImg src={image} alt={title} />
                        </div>
                        <div className="dt-txt">
                            <h2>{title}</h2>
                            <hr />
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html: summary,
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />

                            {link_url && (
                                <a
                                    className="site-link"
                                    href={link_url}
                                    target="_blank"
                                >
                                    {link_text}
                                    <i className="fa fa-external-link" />
                                </a>
                            )}
                        </div>
                        {isSICategory(carrier.category_id) && carrier.cta ? (
                            <div className="dt-cta">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: carrier.cta.text,
                                    }}
                                />
                                <a
                                    className="btn btn-orange"
                                    href={carrier.cta.url}
                                    target="_blank"
                                >
                                    {carrier.cta.label}
                                </a>
                            </div>
                        ) : null}
                        {!isSICategory(carrier.category_id) ? (
                            is_freight && logged_in ? null : (
                                <div className="dt-cta">
                                    <div>To install {title}</div>
                                    {!logged_in ? (
                                        <Fragment>
                                            <a
                                                className="btn btn-orange"
                                                href="/signup"
                                            >
                                                Sign up
                                            </a>
                                            <div>
                                                <a
                                                    href="/login"
                                                    className="login"
                                                >
                                                    Or Login Here
                                                </a>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <a
                                            className="btn btn-orange"
                                            href={
                                                '/ratesmgr/marketplace/' + name
                                            }
                                            target="_blank"
                                        >
                                            Go to Dashboard
                                        </a>
                                    )}
                                </div>
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    ) : null

export default connect(
    ({
        app: { logged_in, integrations: { selected_carrier, logos } = {} },
    }) => ({
        carrier: selected_carrier,
        logos,
        logged_in,
    })
)(FrontendDetailsPage)
