//
import React from 'react'
import { connect } from 'react-redux'
import railsRoutes from 'utils/railsRoutes'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'

/**
 * OtherDetailsPage Properties
 */

export const OtherDetailsPage = (props) => {
    const { data, logos } = props
    if (!data) {
        return null
    }

    const { image, name } = data

    return (
        <div className="carrier-details other max-cont-l">
            <div className="dt-cont clearfix">
                <div
                    className={
                        'dt-img' +
                        (logos && logos[name + 'ComingSoon']
                            ? ' coming-soon'
                            : '')
                    }
                >
                    <LogoImg src={image} />
                </div>

                <div className="dt-txt">
                    <h2>{data.title}</h2>
                    <hr />
                    <h4 dangerouslySetInnerHTML={{ __html: data.summary }} />
                    <div
                        dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                    {data.link_url && (
                        <a
                            className="site-link"
                            href={data.link_url}
                            target="_blank"
                        >
                            {data.link_text}
                            <i className="fa fa-external-link" />
                        </a>
                    )}
                </div>
                {data.cta ? (
                    <div className="dt-cta">
                        <div
                            dangerouslySetInnerHTML={{ __html: data.cta.text }}
                        />
                        <a
                            className="btn btn-primary-o"
                            href={data.cta.url}
                            target="_blank"
                        >
                            {data.cta.label}
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default connect(
    ({ app: { integrations: { selected_carrier, logos } = {} } }) => ({
        data: selected_carrier,
        logos,
    })
)(railsRoutes(OtherDetailsPage))
