import React, { useState } from 'react'
import Modal from 'components/ui/Modal'
import useSteps from 'hooks/useSteps'
import { useSelector } from 'react-redux'
import useInstallIntegration from '../../../hooks/useInstallIntegration'
import { useGetStoreOrderPermission } from '../../../hooks/useStoreOrderPermission'

import { CrossBorderFeature } from './CrossBorderFeature'
import { Success } from './Success'
import { Credentials } from './Credentials'
import { InstallView } from './InstallView'
import { EULAAgreement } from './EULAAgreement'

export const InstallZonosModal = ({
    onInstall,
    onClose,
    installView = false,
    integration,
}) => {
    const { currentStep, goToBackStep, goToNextStep } = useSteps()
    const { isGettingStoreOrderPermission, canStoreQuoteOrder } =
        useGetStoreOrderPermission()

    const { enabled_features } = useSelector((store) => ({
        enabled_features: store.app.enabled_features,
        ...store.app.integrations,
    }))

    const [enabledCrossBorder, _] = useState(
        enabled_features.includes('cross_border')
    )

    const { install, isLoading: isInstalling } = useInstallIntegration('zonos')

    const loading = isGettingStoreOrderPermission

    const handleInstall = (values) => {
        install(values, {
            onSuccess: () => {
                onInstall()
                goToNextStep()
            },
            onError: (errors) => {
                console.log(errors)
            },
        })
    }

    const onContinue = () => {
        goToNextStep()
    }

    let steps = []

    if (!canStoreQuoteOrder) {
        steps = [
            ...steps,
            <EULAAgreement onContinue={onContinue} onCancel={onClose} />,
        ]
    }

    if (!enabledCrossBorder) {
        steps = [
            ...steps,
            <CrossBorderFeature onContinue={onContinue} onCancel={onClose} />,
        ]
    }

    if (installView) {
        steps = [
            ...steps,
            <InstallView
                enabledCrossBorder={enabledCrossBorder}
                logo={integration.image}
                onContinue={onContinue}
                onEnabled={onContinue}
                onCancel={onClose}
            />,
        ]
    }

    steps = [
        ...steps,
        <Credentials
            carrier={integration}
            loading={isInstalling}
            onSubmit={handleInstall}
            onCancel={onClose}
        />,
        <Success
            logo={integration.image}
            carrier={integration}
            onCancel={onClose}
        />,
    ]

    return (
        <Modal
            modalName="zonos-install"
            captureNotifications={false}
            onClose={onClose}
            onBack={currentStep < steps.length ? goToBackStep : null}
            className="modal-content install-app"
        >
            {!loading &&
                steps.map((Step, index) => (
                    <div key={`zonos-${index}-step`}>
                        {currentStep === index + 1 ? Step : null}
                    </div>
                ))}
        </Modal>
    )
}
