//
import React from 'react'
import RequiresFeature from 'components/dashboard/RequiresFeature'
import { IconCrossborder } from 'components/dashboard/features/Icons'
/**
 * LTLWarning Properties
 */

export const CrossBorderFeature = ({ onContinue, onCancel }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <div className="center">
                <h3 className="normal">Feature required...</h3>
            </div>

            <RequiresFeature
                feature="cross_border"
                onContinue={onContinue}
                onCancel={onCancel}
                logo={IconCrossborder}
            >
                <h4 className="normal">
                    To install Zonos, the <strong>Landed Cost Engine</strong>{' '}
                    feature must
                    <br /> be enabled.
                </h4>
            </RequiresFeature>
        </div>
    )
}
