import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import RecommendedCarrier from './RecommendedCarrier'
import ShipStation from 'components/dashboard/integrations/services/ShipStation/ShipStation'
import {
    isCarrierShipStation,
    canInstallCarrierForPlan,
} from 'components/dashboard/integrations/services/utils'

/**
 * RecommendedCarriers Properties
 */

export default function RecommendedCarriers({ carriers = [], category }) {
    const isFrontend = useSelector(({ app }) => app?.frontend)
    const plan = useSelector(({ app }) => app?.plan)
    // Temporary solution for having a dynamic number
    // of columns based on the total carriers. Should be
    // refactored to styled components
    useEffect(() => {
        const recommendedContainer = document.querySelector('.recommended-apps')
        if (carriers?.length % 4 === 0) {
            recommendedContainer?.style.setProperty('--total-columns', '2')
        } else {
            recommendedContainer?.style.setProperty('--total-columns', '3')
        }
    }, [carriers])

    if (!carriers || !carriers.length) {
        return null
    }

    return (
        <div className="recommended">
            <div data-testid="recommendedApps" className="recommended-apps">
                {/* Only display the new ShipStation component if
                    we're not on the /marketplace since it requires
                    authorized user data. It will default to the
                    "carrier" implementation without the custom logic. */}
                {carriers
                    .filter((carrier) => {
                        if (!category) {
                            return carrier.category_id !== 'other'
                        }
                        return true
                    })
                    .map((carrier) =>
                        !isFrontend && isCarrierShipStation(carrier) ? (
                            <ShipStation
                                key={carrier.name}
                                canInstall={canInstallCarrierForPlan(
                                    carrier,
                                    plan
                                )}
                            />
                        ) : (
                            <RecommendedCarrier
                                key={carrier.name}
                                carrier={carrier}
                            />
                        )
                    )}
            </div>
        </div>
    )
}
