//
import { createContext } from 'react'

const SearchContext = createContext({
    setSearchQuery: (query) => {},
    setSearchFunction: (callback) => {},
    search: () => {},
})

export default SearchContext
