//
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showShipStationWizard } from 'reducers/modules/integrations'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'
import useInstallIntegration from '../../hooks/useInstallIntegration'
import useGetShipStation from './useGetShipStation'
import { ShipStationWizard } from './ShipStationWizard'
import { canInstallCarrierForPlan } from 'components/dashboard/integrations/services/utils'

export function ShipStationDetailsPage() {
    const { shipStation, isGettingShipStation, refetch } = useGetShipStation()
    const isShipStationWizardShowing = useSelector(
        ({ app }) => app?.integrations?.isShipStationWizardShowing
    )
    const plan = useSelector(({ app }) => app?.plan)

    if (isGettingShipStation) {
        return 'Loading'
    }

    const canInstall = canInstallCarrierForPlan(shipStation, plan)

    return (
        <>
            <div className="carrier-details other max-cont-l">
                <div className="dt-cont clearfix">
                    <div className="dt-img">
                        <LogoImg src={shipStation.image} />
                    </div>

                    {shipStation.installed ? (
                        <ShipStationInstalledView onUninstall={refetch} />
                    ) : (
                        <ShipStationUninstalledView
                            onInstallClick={showShipStationWizard}
                            isInstallDisabled={!canInstall}
                        />
                    )}
                    <div>
                        <h4>{shipStation.summary}</h4>
                        <div
                            style={{ maxWidth: '30%', float: 'right' }}
                            dangerouslySetInnerHTML={{
                                __html: shipStation.description,
                            }}
                        />
                    </div>
                </div>
            </div>
            {isShipStationWizardShowing && <ShipStationWizard />}
        </>
    )
}

function ShipStationUninstalledView({ isInstallDisabled }) {
    const dispatch = useDispatch()
    const onInstallClick = () => dispatch(showShipStationWizard())

    return (
        <div className="dt-txt">
            <h2>ShipStation</h2>
            <hr />
            <h4>Optimize your post purchase order management and labeling</h4>
            <p>
                ShipperHQ’s partnership with ShipStation bolsters the end-to-end
                shipping experience and enables you to manage you shipping
                experience from start to finish.
            </p>
            <p>
                Installing the ShipStation integration will allow you to import
                important order shipping details from ShipperHQ to ShipStation.
            </p>

            <button
                data-testid="agree-step-install-button"
                type="button"
                className="btn btn-primary"
                disabled={isInstallDisabled}
                onClick={onInstallClick}
            >
                { isInstallDisabled ? 'Upgrade to install' : 'Install' }
            </button>
        </div>
    )
}

function ShipStationInstalledView({ onUninstall }) {
    const { uninstall, isLoading } = useInstallIntegration('shipstation')

    const handleUninstall = () => {
        uninstall({}, { onSuccess: () => onUninstall() })
    }

    return (
        <div className="dt-txt">
            <h2>ShipStation</h2>
            <hr />
            <div className="validate-result">
                <div className="success mb20">
                    <h4>ShipStation is Successfully Installed</h4>
                    <p>
                        Manage and generate the tokens used to connect to
                        ShipStation from the "Integrations" tab for any website
                        listed on the{' '}
                        <a href="/ratesmgr/websites">websites page</a>.
                    </p>
                    <a
                        href="https://docs.shipperhq.com/how-to-connect-shipperhq-to-shipstation/"
                        target="_blank"
                    >
                        <i className="fal fa-info-circle" /> How to connect
                        ShipperHQ to ShipStation
                    </a>
                </div>
            </div>
            <button
                className="btn btn-primary"
                disabled={isLoading}
                onClick={handleUninstall}
            >
                Uninstall
            </button>
        </div>
    )
}

export default ShipStationDetailsPage
