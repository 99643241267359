import { useDispatch } from 'react-redux'
import { configureCarrier } from 'reducers/modules/integrations'
import { isCarrierUPSMyChoice } from 'components/dashboard/integrations/services/utils'
import { useModalController } from 'components/ui/ModalProvider'
import { IntegrationModalIds } from 'components/dashboard/integrations/modals'

/**
 * Handles installation of carriers and services
 * @param carrier
 * @returns {(function(*=): void)|*}
 */
export function useInstallServiceAction(carrier) {
    const dispatch = useDispatch()
    const { showModal } = useModalController()

    return (carrier) => {
        switch (true) {
            case isCarrierUPSMyChoice(carrier):
                showModal(IntegrationModalIds.UPS_MY_CHOICE_INSTALLED)
                break

            default:
                dispatch(configureCarrier(carrier))
                break
        }
    }
}
