export function isCarrierUPSMyChoice(carrier) {
    return carrier.name.toLowerCase().includes('upsmychoice')
}

export function isCarrierZonos(carrier) {
    return carrier.name.toLowerCase().includes('zonos')
}

export function isCarrierShipStation(carrier) {
    return carrier.title.toLowerCase().includes('shipstation')
}

export function canInstallCarrierForPlan(carrier, plan) {
    if (!Array.isArray(carrier.plans)) return true; // if there is no white-list, so don't limit it

    return carrier.plans.includes(plan)
}
