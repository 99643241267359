import React from 'react'
import useCarrierTitle from 'components/dashboard/integrations/containers/CarrierWizard/hooks/useCarrierTitle'
import UpgradePlanFooter from 'components/dashboard/integrations/containers/CarrierWizard/components/UpgradePlanFooter'

export default function CarrierDetailsActions({
    canInstall,
    carrier,
    addAnother,
}) {
    const carrierTitle = useCarrierTitle(carrier.name)

    if (!canInstall) {
        return <UpgradePlanFooter />
    }

    return (
        <>
            <button
                onClick={addAnother}
                type="button"
                className="btn btn-primary"
                title="test"
            >
                {`Add Another ${carrierTitle} Account`}
            </button>
        </>
    )
}
