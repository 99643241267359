import React from 'react'

const ListRatesLabel: React.FC<{
    carrierIdentifier: string
}> = ({ carrierIdentifier }) => {
    let label: string
    switch (carrierIdentifier) {
        case 'ups':
            label = 'Published Daily Rates'
            break
        case 'usps':
            label = 'Online Rates'
            break
        default:
            label = 'List Rates'
            break
    }

    return (
        <div>
            <span className="rate-type">{label}</span>
        </div>
    )
}

interface Props {
    carrier?: Partial<Carrier>
}

const ValidationStatus: React.FC<{
    carrier: Carrier
}> = ({
    carrier: {
        id,
        installed,
        validated,
        carrier_type,
        name,
        list_rates = false,
    } = {},
}: Props) => {
    const carrierIsInstalled = installed || id
    // sometimes the fields are missing, so we just need something
    // that can identify the carrier. Type is the best identifier
    // next is name
    const carrierIdentifier = carrier_type || name

    // Check for list rates first since the carrier won't be
    // validated unless they are using list rates
    if (list_rates && carrierIsInstalled) {
        return <ListRatesLabel carrierIdentifier={carrierIdentifier} />
    }

    if (!validated && carrierIsInstalled) {
        return (
            <div className="creds-warning">
                Needs Credentials <i className="fas fa-circle" />
            </div>
        )
    }

    return null
}

export default ValidationStatus
