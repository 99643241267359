import { useSelector } from 'react-redux'

export function useUPSMyChoiceInstallStatus() {
    const installed = useSelector(
        ({
            app: {
                // eslint-disable-next-line camelcase
                user_settings: { enable_ups_my_choice } = {},
            } = {},
            // eslint-disable-next-line camelcase
        }) => enable_ups_my_choice
    )

    return installed
}
