//
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { showShipStationWizard } from 'reducers/modules/integrations'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'
import { UPSMyChoiceInstaller } from 'components/dashboard/integrations/services/UPSMyChoice/UPSMyChoiceInstaller'
import { useUPSMyChoiceInstallStatus } from 'components/dashboard/integrations/services/UPSMyChoice/useUPSMyChoiceInstallStatus'
import { useUPSMyChoiceInstaller } from 'components/dashboard/integrations/services/UPSMyChoice/useUPSMyChoiceInstaller'
import logger from 'utils/logger'

export function UPSMyChoicePage() {
    const installed = useUPSMyChoiceInstallStatus()
    const upsMyChoice = useSelector(
        (store) => store.app.integrations?.selected_carrier || {}
    )

    return (
        <>
            <div className="carrier-details other max-cont-l">
                <div className="dt-cont clearfix">
                    <div className="dt-img">
                        <LogoImg src={upsMyChoice.image} />
                    </div>

                    {installed ? (
                        <UPSMyChoiceInstalledView
                            onInstallClick={showShipStationWizard}
                            isInstallDisabled={false}
                        />
                    ) : (
                        <div className="dt-txt">
                            <UPSMyChoiceInstaller />
                        </div>
                    )}
                    <div>
                        <h4>{upsMyChoice.summary}</h4>
                        <div
                            style={{ maxWidth: '30%', float: 'right' }}
                            dangerouslySetInnerHTML={{
                                __html: upsMyChoice.description,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

function UPSMyChoiceInstalledView() {
    const [loading, setLoading] = useState(false)
    const { uninstall } = useUPSMyChoiceInstaller()
    const doUninstall = async () => {
        setLoading(true)
        try {
            await uninstall()
        } catch (e) {
            logger.error(e)
        }
        setLoading(false)
    }

    return (
        <div className="dt-txt">
            <h2>UPS My Choice&reg;</h2>
            <hr />
            <div className="validate-result">
                <div className="success mb20">
                    <h4>UPS My Choice&reg; Installed Successfully</h4>
                    <p>
                        You will now need to{' '}
                        <a
                            href="https://docs.shipperhq.com/how-to-set-up-ups-my-choice/"
                            target="_blank"
                        >
                            install the Adobe Commerce extension
                        </a>{' '}
                        to enable this feature so your customers can sign up for
                        UPS My Choice when their order is confirmed.
                    </p>
                    <a
                        href="https://docs.shipperhq.com/how-to-set-up-ups-my-choice/"
                        target="_blank"
                    >
                        <i className="fal fa-info-circle" /> UPS My Choice&reg;
                        Documentation
                    </a>
                </div>
            </div>
            <button
                className="btn btn-primary"
                disabled={loading}
                onClick={() => doUninstall()}
            >
                Uninstall
            </button>
        </div>
    )
}
