//
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import railsRoutes from 'utils/railsRoutes'
import { connect } from 'react-redux'
import { selectCarrier } from 'reducers/modules/integrations'
import {
    shortCategory,
    carrierDivStyle,
} from 'components/dashboard/integrations/containers/Index/components/utils'

/**
 * GenericEntry Properties
 */

export const GenericEntry = ({
    logos,
    data: { title, name, category, display_category_id },
    landing_pages,
    frontend,
    data,
    routes,
    history,
    selectCarrier,
}) => {
    const url = routes.ratesmgr_marketplace_path(name)
    const handleClick = (e) => {
        e.preventDefault()
        if (frontend && landing_pages[name]) {
            window.open(landing_pages[name], '_self')
            return false
        }

        selectCarrier(data)
        history.push(url)
    }
    return (
        <a
            href={url}
            data-test-url={url}
            className={
                'app-2' +
                (logos && logos[name + 'ComingSoon'] ? ' coming-soon' : '')
            }
            onClick={handleClick}
        >
            <div className="logo" style={carrierDivStyle(logos, name)}>
                <img src={logos ? logos[name] : null} alt={title} />
            </div>
            <div className="info">
                <div className="title">
                    <h4>{title}</h4>
                    <p className="type">
                        {shortCategory(category, display_category_id)}
                    </p>
                </div>
            </div>
        </a>
    )
}

export default withRouter(
    railsRoutes(
        connect(
            ({
                app: {
                    frontend,
                    integrations: { logos, landing_pages } = {},
                } = {},
            }) => ({ logos, landing_pages, frontend }),
            { selectCarrier }
        )(GenericEntry)
    )
)
