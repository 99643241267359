import React, { useState } from 'react'
import { Box, Button, Flex, Heading } from 'styles'
import styled from 'styled-components'
import OrderStorageAgreement from 'components/dashboard/components/OrderStorageAgreement'
import { color, layout, position, space, typography } from 'styled-system'

const Label = styled.label`
    ${color}
    ${typography}
    ${space}
    ${position}
`
const Input = styled.input`
    margin: 0;
    ${color}
    ${typography}
    ${space}
    ${layout}
`

// TODO: figure out primary styles
const PrimaryButton = styled(Button)``

export default function EULAAgreementForm({ onSubmit }) {
    const [isAgreed, setIsAgreed] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }
    const handleChange = () => {
        setIsAgreed((isAgreed) => !isAgreed)
    }
    return (
        <Box px={12} data-testid="eula-agreement-form">
            <Heading level="2">Installing ShipStation</Heading>
            <hr />
            <Box textAlign="center">
                <Heading fontWeight="bold" fontSize="1em" level="3">
                    Storing Order Information
                </Heading>
            </Box>
            <form onSubmit={handleSubmit}>
                <OrderStorageAgreement>
                    <Flex alignItems="start" justifyContent="start">
                        <Input
                            id="agree"
                            type="checkbox"
                            name="agree"
                            checked={isAgreed}
                            onChange={handleChange}
                        />
                        <Label
                            top="3px"
                            position="relative"
                            fontSize="0.85em"
                            htmlFor="agree"
                        >
                            I agree to allow ShipperHQ to handle the order
                            information as described above
                        </Label>
                    </Flex>
                </OrderStorageAgreement>
                <PrimaryButton
                    data-testid="agree-step-install-button"
                    disabled={!isAgreed}
                    className="btn btn-primary"
                    type="submit"
                >
                    Install
                </PrimaryButton>
            </form>
        </Box>
    )
}
