import { useQuery } from 'react-query'
import { axiosClient } from 'net/ajax'

function getShipStation() {
    return axiosClient
        .get('/ratesmgr/marketplace/show_shipstation')
        .then(({ data }) => data)
}

export default function useGetShipStation() {
    const {
        data: shipStation,
        isLoading: isGettingShipStation,
        refetch,
    } = useQuery(['integrations', 'shipstation'], getShipStation)

    return {
        shipStation,
        isGettingShipStation,
        refetch,
    }
}
