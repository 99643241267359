import React, { useState } from 'react'
import { useUPSMyChoiceInstaller } from 'components/dashboard/integrations/services/UPSMyChoice/useUPSMyChoiceInstaller'
import { Box, Button, Heading } from 'styles'
import logger from 'utils/logger'

export function UPSMyChoiceInstaller({ onInstall }) {
    const [loading, setLoading] = useState(false)
    const { install } = useUPSMyChoiceInstaller()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await install()
            if (onInstall) {
                onInstall()
            }
        } catch (e) {
            logger.error(e)
        }
        setLoading(false)
        return false
    }

    return (
        <>
            <Heading level="2">Installing UPS My Choice&reg;</Heading>
            <hr />
            <Box>
                <h4>Give your customers a better delivery experience</h4>
                <p>
                    UPS My Choice® is a membership program that provides
                    customers with unprecedented visibility over their incoming
                    packages:
                </p>
                <ul>
                    <li>
                        Delivery alerts provide timely notification of incoming
                        shipments
                    </li>
                    <li>
                        Delivery change options ensure customers receive
                        deliveries on their schedule
                    </li>
                    <li>
                        Customers can sign for a package or leave delivery
                        instructions for their driver from their phone, the UPS
                        app or their desktop computer
                    </li>
                </ul>
                <p>
                    UPS is committed to helping small and medium-sized
                    businesses succeed by meeting customers’ rising
                    expectations.
                </p>
                <p>
                    Installing UPS My Choice<sup>&reg;</sup> in ShipperHQ and
                    the required Adobe Commerce extension will allow your
                    customers to sign up for Installing UPS My Choice
                    <sup>&reg;</sup> once their order is confirmed.
                </p>
            </Box>
            <form onSubmit={handleSubmit}>
                <Button
                    data-testid="agree-step-install-button"
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                >
                    Install
                </Button>
            </form>
        </>
    )
}
