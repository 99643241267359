//
import React, { Component } from 'react'
import breadCrumbs from 'utils/breadCrumbs'
import { connect } from 'react-redux'
import railsRoutes from 'utils/railsRoutes'
import { withRouter } from 'react-router'
import Loader from 'components/Loader'
import { isCarrierShipStation } from 'components/dashboard/integrations/services/utils'
import OtherDetailsPage from './components/OtherDetailsPage'
import CarrierDetailsPage from './components/CarrierDetailsPage'
import FrontendDetailsPage from './components/FrontendDetailsPage'
import { isSICategory } from './components/utils'
import { ShipStationDetailsPage } from 'components/dashboard/integrations/services/ShipStation/ShipStationDetailsPage'
import { ZonosDetailsPage } from 'components/dashboard/integrations/services/Zonos/DetailsPage'
import { isCarrierUPSMyChoice } from 'components/dashboard/integrations/services/utils'
import { isCarrierZonos } from 'components/dashboard/integrations/services/utils'
import { UPSMyChoicePage } from 'components/dashboard/integrations/services/UPSMyChoice'

class Details extends Component {
    componentDidMount() {
        const { carrier } = this.props

        if (carrier) {
            this.props.pushBreadcrumb({
                title: carrier ? carrier.title : 'Unknown carrier',
            })
        }

        window.scrollTo(0, 0)
    }

    render() {
        const { carrier, frontend } = this.props

        switch (true) {
            case !carrier:
                return <Loader />
            case frontend:
                return <FrontendDetailsPage />
            case isCarrierShipStation(carrier):
                return <ShipStationDetailsPage />
            case isCarrierZonos(carrier):
                return <ZonosDetailsPage />
            case isCarrierUPSMyChoice(carrier):
                return <UPSMyChoicePage />
            case isSICategory(carrier.category_id):
                return <OtherDetailsPage />
            default:
                return <CarrierDetailsPage />
        }
    }
}

export default withRouter(
    connect(
        ({ app: { frontend, integrations: { selected_carrier } = {} } }) => ({
            frontend,
            carrier: selected_carrier,
        })
    )(
        breadCrumbs(
            [
                {
                    title: 'Marketplace',
                    route: 'ratesmgr_marketplace_index_path',
                },
            ],
            {
                setOnMount: false,
            }
        )(railsRoutes(Details))
    )
)
