import { useMutation } from 'react-query'
import { axiosClient } from 'net/ajax'
import { updateUserSetting } from 'reducers/modules/app'
import { useDispatch } from 'react-redux'

async function updateUPSMyChoice(install = true) {
    const endpoint = '/ratesmgr/marketplace/enable_ups_my_choice'

    const { status, data } = await axiosClient.post(endpoint, {
        enable: install,
    })

    if (status === 200) {
        return data
    }

    return undefined
}

export function useUPSMyChoiceInstaller() {
    const dispatch = useDispatch()
    const {
        isLoading,
        mutateAsync,
        isSuccess: isInstallSuccess,
        error: installError,
        isError: isInstallError,
    } = useMutation((shouldInstall) => updateUPSMyChoice(shouldInstall), {
        onSuccess: (data) => {
            if (data.enabled !== undefined) {
                dispatch(
                    updateUserSetting({
                        key: 'enable_ups_my_choice',
                        value: data.enabled,
                    })
                )
            }
        },
    })

    return {
        isInstallSuccess,
        install: () => mutateAsync(true),
        uninstall: (options = {}) => mutateAsync(false, options),
        isLoading,
        installError,
        isInstallError,
    }
}
