//
import React from 'react'
import { connect } from 'react-redux'
import { configureCarrier } from 'reducers/modules/integrations'

/**
 * InstallStatus Properties
 */

const InstallStatus = ({
    carrier: { id, installed } = {},
    canInstall,
    onInstall,
    disabled,
}) => {
    const extraProps = {}
    if (!canInstall) {
        extraProps.disabled = true
    }

    if (!installed && !id) {
        const extraProps = {}
        if (!canInstall || disabled) {
            extraProps.disabled = true
        }
        return ''
    }

    return (
        <div className="installed">
            Installed <i className="fas fa-check" />
        </div>
    )
}

export default connect(
    ({ app: { integrations: { can_install } = {} } = {} }) => ({
        canInstall: can_install,
    }),
    { configureCarrier }
)(InstallStatus)
