import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'components/ui/Modal'
import { hideShipStationWizard } from 'reducers/modules/integrations'
import useWebsites from './useWebsites'
import useSteps from 'hooks/useSteps'
import GenerateTokenResult from './GenerateTokenResult'
import GenerateTokenForm from './GenerateTokenForm'
import EULAAgreementForm from './EULAAgreementForm'
import useInstallIntegration from '../../hooks/useInstallIntegration'
import { useGetStoreOrderPermission } from '../../hooks/useStoreOrderPermission'
import { useCreateToken } from 'hooks/integrations'

export function ShipStationWizard() {
    const { token, create, isFetchingToken } = useCreateToken()
    const {
        isFetchingWebsites,
        websites,
        selectedWebsite,
        handleWebsiteChange,
    } = useWebsites()
    const { currentStep, goToNextStep, setCurrentStep } = useSteps()
    const dispatch = useDispatch()
    const closeModal = () => dispatch(hideShipStationWizard())
    const {
        install,
        installAsync,
        isLoading: isUpdatingShipStation,
    } = useInstallIntegration('shipstation')
    const { isGettingStoreOrderPermission, canStoreQuoteOrder } =
        useGetStoreOrderPermission()

    // In order to Skip the permission step if the user already accepted them
    // Also skips the website step so the user doesn't have to wait for these
    //
    useEffect(() => {
        if (canStoreQuoteOrder && websites?.length === 1) {
            const [websiteId] = websites[0]
            installAsync()
                .then(() =>
                    create({ websiteId, integrationType: 'shipstation' })
                )
                .then(() => setCurrentStep(3))
        } else if (canStoreQuoteOrder) {
            installAsync().then(() => setCurrentStep(2))
        } else {
            setCurrentStep(1)
        }
    }, [canStoreQuoteOrder, websites])

    const handleAgreementSubmit = () => {
        install()
        goToNextStep()
    }
    const handleGenerateTokenSubmit = () => {
        create({ websiteId: selectedWebsite, integrationType: 'shipstation' })
        goToNextStep()
    }
    const handleOkClick = () => {
        closeModal()
    }

    const loading =
        isGettingStoreOrderPermission ||
        isFetchingWebsites ||
        isUpdatingShipStation ||
        isFetchingToken

    return (
        <Modal
            modalName="shipstation-install"
            captureNotifications={false}
            onClose={closeModal}
            className="modal-content install-app"
        >
            {loading ? (
                'Loading'
            ) : (
                <>
                    {currentStep === 1 && (
                        <EULAAgreementForm onSubmit={handleAgreementSubmit} />
                    )}
                    {currentStep === 2 && (
                        <GenerateTokenForm
                            onSubmit={handleGenerateTokenSubmit}
                            handleWebsiteChange={handleWebsiteChange}
                            selectedWebsite={selectedWebsite}
                            websites={websites}
                        />
                    )}
                    {currentStep === 3 && (
                        <GenerateTokenResult
                            onOkClick={handleOkClick}
                            isSuccess={true}
                            website={getWebsiteNameFromId(
                                websites,
                                selectedWebsite
                            )}
                            token={token}
                        />
                    )}
                </>
            )}
        </Modal>
    )
}

function getWebsiteNameFromId(websites, id) {
    const website = websites.find(([websiteId]) => websiteId === Number(id))
    return website ? website[1] : undefined
}
