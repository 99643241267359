import React from 'react'
import useInstallIntegration from '../../hooks/useInstallIntegration'

export const UninstallZonos = ({ carrier, onUninstall }) => {
    const { uninstall, isLoading } = useInstallIntegration('zonos')

    const handleUninstall = () => {
        uninstall(
            {},
            {
                onSuccess: () => onUninstall && onUninstall(),
            }
        )
    }

    return (
        <>
            <div className="validate-result">
                <div className="success mb-5">
                    <h4>Zonos is successfully installed</h4>
                    <a href={carrier.install_url} target="_blank">
                        <i className="fal fa-info-circle" /> How to Set Up Zonos
                    </a>
                </div>
            </div>
            <button
                className="btn btn-primary"
                disabled={isLoading}
                onClick={handleUninstall}
            >
                Uninstall Zonos
            </button>
        </>
    )
}
