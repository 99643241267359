//
import React from 'react'

import CarrierWizard from '../CarrierWizard'
import { showNotification } from 'reducers/modules/app'
import { useDispatch } from 'react-redux'

/**
 * AccountValidator Properties
 */

function AccountValidator(props) {
    const dispatch = useDispatch()
    const { carrier, account, onClose, onInstall } = props
    const displayError = (error) => dispatch(showNotification(error))

    return (
        <CarrierWizard
            carrier={carrier}
            account={account}
            inline={true}
            onInstall={onInstall}
            displayError={displayError}
            onClose={onClose}
        />
    )
}

export default AccountValidator
