//
import React from 'react'
import { connect } from 'react-redux'
import { useInstallServiceAction } from 'components/dashboard/integrations/hooks/useInstallServiceAction'
import { useServiceInstallStatus } from 'components/dashboard/integrations/hooks/useServiceInstallStatus'

/**
 * InstallStatus Properties
 */

const InstallStatus = ({ carrier: { id } = {}, carrier, canInstall }) => {
    const extraProps = {}
    const installCarrier = useInstallServiceAction()
    const installed = useServiceInstallStatus(carrier)

    if (!canInstall) {
        extraProps.disabled = true
    }

    if (!installed && !id) {
        return (
            <button
                className="btn btn-sm btn-basic"
                title="Install"
                onClick={(e) => {
                    e.stopPropagation()
                    installCarrier(carrier)
                }}
            >
                Install
            </button>
        )
    }

    return (
        <div className="installed">
            Installed <i className="fas fa-check" />
        </div>
    )
}

export default connect(
    ({ app: { integrations: { can_install } = {} } = {} }) => ({
        canInstall: can_install,
    })
)(InstallStatus)
