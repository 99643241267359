import React, { useMemo, createContext, useReducer, useContext } from 'react'

const ModalControllerContext = createContext({})

export function useModalController() {
    const { state, dispatch } = useContext(ModalControllerContext)

    return useMemo(() => {
        return {
            showModal(modal) {
                dispatch({
                    type: 'SHOW_MODAL',
                    modal,
                })
            },
            hideModal() {
                dispatch({
                    type: 'HIDE_MODAL',
                })
            },
        }
    }, [state])
}

function reducer(state, action) {
    switch (action.type) {
        case 'HIDE_MODAL':
            return { ...state, activeModal: null }
        case 'SHOW_MODAL':
            return {
                ...state,
                activeModal: action.modal,
            }
        default:
            return state
    }
}

export function ModalProvider({ children, modalMap }) {
    const [state, dispatch] = useReducer(reducer, { activeModal: null })
    const context = { state, dispatch }

    const VisibleModal = state.activeModal && modalMap[state.activeModal]
    const modal = VisibleModal ? (
        <VisibleModal onClose={() => dispatch({ type: 'HIDE_MODAL' })} />
    ) : null

    return (
        <ModalControllerContext.Provider value={context}>
            {modal}
            {children}
        </ModalControllerContext.Provider>
    )
}
