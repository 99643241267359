//
import React from 'react'
import Carrier from './Carrier'
import GenericEntry from './GenericEntry'
import ShipStation from 'components/dashboard/integrations/services/ShipStation/ShipStation'
import { ZonosEntry } from 'components/dashboard/integrations/services/Zonos/ZonosEntry'
import { getCategoryTitle, categoryChildren } from './categories'
import { isSICategory } from '../../Details/components/utils'
import {
    isCarrierZonos,
    isCarrierShipStation,
} from 'components/dashboard/integrations/services/utils'
import { useSelector } from 'react-redux'
import { canInstallCarrierForPlan } from 'components/dashboard/integrations/services/utils'
import { CustomCarrierBanner } from 'components/dashboard/integrations/components/CustomCarrierBanner'

/**
 * CarrierGrid Properties
 */

export default function CarrierGrid({ carriers = [], category }) {
    if (!carriers || carriers.length === 0) {
        return null
    }

    return (
        <div>
            <div className="max-cont">
                <CarrierGroups category={category} carriers={carriers} />
            </div>
        </div>
    )
}

function CarrierGroups({ category, carriers }) {
    const groups = []
    const subCategories = categoryChildren(category)

    if (category && subCategories?.length) {
        for (const subCategory of subCategories) {
            const filteredCarriers = carriers.filter(
                (item) => item.category_id === subCategory.id
            )

            if (filteredCarriers.length) {
                groups.push(
                    <CarrierGroup
                        key={subCategory.id}
                        category={subCategory.id}
                        carriers={filteredCarriers}
                    />
                )
            }
        }
    } else {
        groups.push(
            <CarrierGroup
                key={category}
                category={category}
                carriers={carriers}
            />
        )
    }

    return groups
}

function CarrierGroup({ category, carriers }) {
    const isFrontend = useSelector(({ app }) => app?.frontend)
    const plan = useSelector(({ app }) => app?.plan)
    const isSmallPackage = category === 'small_package'

    return (
        <>
            {category && (
                <h4 className="subcat">{getCategoryTitle(category)}</h4>
            )}
            <div className="secondary-apps">
                {carriers.map((carrier) => {
                    // Only display the new ShipStation component if
                    // we're not on the /marketplace since it requires
                    // authorized user data
                    if (!isFrontend && isCarrierShipStation(carrier)) {
                        return <ShipStation key={carrier.name} canInstall={canInstallCarrierForPlan(carrier, plan)} />
                    } else if (isSICategory(carrier.category_id)) {
                        return (
                            <GenericEntry key={carrier.name} data={carrier} />
                        )
                    } else if (isCarrierZonos(carrier)) {
                        return <ZonosEntry key={carrier.name} carrier={carrier} />
                    } else {
                        return <Carrier key={carrier.name} carrier={carrier} />
                    }
                })}
            </div>

            {/* Only show custom carrier banner for small package carriers */}
            {isSmallPackage && <CustomCarrierBanner />}

        </>
    )
}
