import React, { Fragment, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router'
import LocationSelector from 'components/dashboard/integrations/containers/Index/components/LocationSelector'
import { CategoryButton } from './CategoryButton'
import { Flex } from 'styles'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

export const SearchBarComponent = ({
    query,
    setQuery,
    category,
    setCategory,
    region,
    setRegion,
    categories,
    context,
}) => {
    const mountRef = useRef(false)
    const routerHistory = useHistory()
    const urlParams = new URLSearchParams(window.location.search)

    const filter = {
        category: urlParams.get('category'),
        query: urlParams.get('query'),
        region: urlParams.get('region'),
    }

    const setFilter = (params) => {
        const urlParams = new URLSearchParams()

        if (params.category) urlParams.set('category', params.category)
        if (params.query) urlParams.set('query', params.query)
        if (params.region) urlParams.set('region', params.region)

        const url = `${window.location.pathname}?` + urlParams.toString()
        return routerHistory?.push(url)
    }

    const setFilterCategory = (category) => {
        setFilter({ ...filter, category })
    }

    const setFilterQuery = (event) => {
        setFilter({ ...filter, query: event.target.value })
    }

    const setFilterRegion = (event) => {
        setFilter({ ...filter, region: event.target.value })
    }

    useEffect(() => {
        if (!mountRef.current && isEmpty(window.location.search)) {
            mountRef.current = true
            setFilter({ category, query, region })
            return
        }

        if (filter.query !== query) setQuery(filter.query || '', context)
        if (filter.category !== category) setCategory(filter.category, context)
        if (filter.region && filter.region !== region)
            setRegion(filter.region, context)
    }, [JSON.stringify(filter)])

    return (
        <Fragment>
            <div className="title-head max-cont">
                <h1>Marketplace</h1>

                <Flex flexWrap="wrap">
                    <div className="form-group app-search">
                        <div className="search-s">
                            <input
                                name="filterQuery"
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                onChange={setFilterQuery}
                                value={filter.query || ''}
                            />
                            <span className="search-btn">
                                <i className="fa fa-search" />
                            </span>
                        </div>
                    </div>

                    <div className="filters">
                        <div className="status-filter">
                            <label htmlFor="region-select">Region:</label>

                            <LocationSelector
                                location={filter.region}
                                onLocationChange={setFilterRegion}
                            />
                        </div>
                    </div>
                </Flex>
            </div>

            <CategoryButtonWrapper>
                <CategoryButtonInner className="max-cont">
                    <CategoryButton
                        handleClick={setFilterCategory}
                        id="all"
                        title="All"
                        active={filter.category === 'all'}
                    />

                    {categories.map(({ id, title }) => (
                        <CategoryButton
                            key={id}
                            handleClick={setFilterCategory}
                            id={id}
                            title={title}
                            active={filter.category === id}
                        />
                    ))}
                </CategoryButtonInner>
            </CategoryButtonWrapper>
        </Fragment>
    )
}

const CategoryButtonWrapper = styled.div`
    border-top: 1px #ddd solid;
    background-color: #f2f9fc;
    padding: 17px 30px 0 30px;
    margin-top: 15px;
`

const CategoryButtonInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 1200px) {
        justify-content: space-evenly;
    }
`
