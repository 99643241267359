//
import React from 'react'
import railsRoutes from 'utils/railsRoutes'
import InstallStatus from './InstallStatus'
import ValidationStatus from './ValidationStatus'
import { connect } from 'react-redux'
import { shortCategory, carrierDivStyle } from './utils'
import { withRouter } from 'react-router'
import { selectCarrier } from 'reducers/modules/integrations'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'

export const Carrier = ({
    logos,
    carrier: { title, name, category, display_category_id, slug },
    landing_pages,
    frontend,
    carrier,
    routes,
    history,
    selectCarrier,
}) => {
    const url = routes.ratesmgr_marketplace_path(slug ? slug : name)
    return (
        <a
            href={url}
            data-test-url={url}
            className={
                'app-2' +
                (logos && logos[name + 'ComingSoon'] ? ' coming-soon' : '')
            }
            onClick={(e) => {
                e.preventDefault()
                if (frontend && landing_pages[name]) {
                    window.open(landing_pages[name], '_self')
                    return false
                }
                selectCarrier(carrier)
                history.push(url)
            }}
        >
            <div className="logo" style={carrierDivStyle(logos, name)}>
                <LogoImg src={logos ? logos[name] : null} alt={title} />
            </div>
            <div className="info">
                <div className="title">
                    <h4>{title}</h4>
                    <p className="type">
                        {shortCategory(category, display_category_id)}
                    </p>
                </div>
                {!frontend ? (
                    <div className="status">
                        <InstallStatus carrier={carrier} small={true} />
                        <ValidationStatus carrier={carrier} small={true} />
                    </div>
                ) : null}
            </div>
        </a>
    )
}

export default withRouter(
    railsRoutes(
        connect(
            ({
                app: {
                    frontend,
                    integrations: { logos, landing_pages } = {},
                } = {},
            }) => ({
                frontend,
                landing_pages,
                logos,
            }),
            { selectCarrier }
        )(Carrier)
    )
)
