import React from 'react'
import styled from 'styled-components'

export const CategoryButton = ({ handleClick, id, title, active }) => {
    return (
        <Button
            className={active ? 'active' : ''}
            onClick={() => handleClick(id)}
            data-testid={id}
        >
            {title}
        </Button>
    )
}

const Button = styled.button`
    background-color: #fff;
    border-radius: 21px;
    border: 1px solid #ddd;
    padding: 5px 20px;
    color: #555;
    font-size: 15px;
    margin: 7px 0;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #037fd0;
        color: #fff;
    }

    &.active {
        background-color: #037fd0;
        color: #fff;
    }
`
