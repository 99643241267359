//
import React from 'react'

export const Success = ({ carrier, logo }) => {
    return (
        <>
            <div className="center">
                <h3 className="normal">Zonos was installed successfully</h3>
            </div>
            <div className="c-message">
                <div className="c-named">
                    <div className="carrier-selected carrier-name-reTransFreight c-type-live">
                        <img
                            src={logo}
                            title={`zonos-title`}
                            alt={`zonos-title`}
                        />

                        <i className="fas fa-check-circle ico-success" />
                    </div>
                </div>
            </div>

            <div className="reqs-list center">
                <div className="mb-5 center">
                    <h4 className="normal">
                        Your Zonos account is now connected
                    </h4>
                </div>
            </div>

            <div className="left">
                <p>
                    <strong>What happens now?</strong>
                </p>

                <p className="mb-4">{carrier.install_summary}</p>

                <a href={carrier.install_url} target="_blank">
                    <i className="fal fa-info-circle" /> How to Set Up Zonos
                </a>
            </div>
        </>
    )
}
