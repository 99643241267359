//
import React from 'react'
import { isWebpEnabled } from 'utils/webp'

export default function LogoImg(props) {
    const { src, alt } = props

    if (!src) {
        return null
    }

    return (
        <picture>
            {isWebpEnabled && (
                <source type="image/webp" srcSet={src + '.webp'} />
            )}
            <img src={src} alt={alt} />
        </picture>
    )
}
