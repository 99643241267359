//
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'

/**
 * CarrierDetails Properties
 */

const CarrierDetails = ({ form, name, logos }) =>
    form ? (
        <div className="summary">
            <div
                className={
                    'app-logo' +
                    (logos && logos[name + 'ComingSoon'] ? ' coming-soon' : '')
                }
            >
                <LogoImg src={form.image} />
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: form.description,
                }}
            />
            {form.link_url && (
                <a className="site-link" href={form.link_url} target="_blank">
                    {form.link_text}
                    <i className="fa fa-external-link" />
                </a>
            )}
        </div>
    ) : null

export default connect(({ app: { integrations: { logos } = {} } = {} }) => ({
    logos,
}))(CarrierDetails)
