//
import React, { Component } from 'react'

/**
 * AccountChooser Properties
 */

const AccountChooser = ({ onChange, onInstall, accounts, selected }) =>
    accounts && accounts.length > 1 ? (
        <div className="acct-drop">
            <div className="select-s">
                <label htmlFor="carrier-account">
                    View Account Credentials for:
                </label>
                <select
                    id="carrier-account"
                    className="form-control select optional"
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    value={selected}
                >
                    {accounts.map((account) => (
                        <option key={account.id} value={account.id}>
                            {account.name ? account.name : '- NOT CONFIGURED -'}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    ) : null

export default AccountChooser
