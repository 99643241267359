import React from 'react'
import { Box, Heading, Button, Text, Hr, Flex } from 'styles'
import styled from 'styled-components'
import { color, typography, space, layout } from 'styled-system'

const Label = styled.label``
const Select = styled.select`
    ${space}
    ${layout}
`
const Option = styled.option``
const FAIcon = styled.i`
    font-size: 24px;
    ${layout}
    ${color}
    ${typography}
`

export default function GenerateTokenForm({
    onSubmit,
    websites,
    selectedWebsite,
    handleWebsiteChange,
}) {
    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }
    return (
        <Box px={12} textAlign="left" data-testid="generate-token-form">
            <Box textAlign="center">
                <FAIcon
                    color="green1"
                    className="fas fa-check-circle"
                    role="img"
                    aria-label="Check mark"
                />
                <Heading mt={6} level="2">
                    ShipStation Installed Successfully
                </Heading>
            </Box>
            <Hr mb={12} />
            <Box mb={8}>
                <Heading fontWeight="bold" fontSize="1em" level="3">
                    What happens now?
                </Heading>
                <Text>
                    ShipperHQ is now able to be connected to your ShipStation
                    account. Generate an access token here for the website you
                    would like to use to send details to ShipStation.
                </Text>
            </Box>
            <form onSubmit={handleSubmit}>
                <Box mb={12}>
                    <Label htmlFor="website-selector">Website*</Label>

                    <Select
                        id="website-selector"
                        value={selectedWebsite}
                        onChange={handleWebsiteChange}
                        width="100%"
                        px={4}
                        py={2}
                    >
                        {websites.map(([id, name]) => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </Box>

                <Flex justifyContent="center">
                    <Button
                        className="btn btn-primary"
                        data-testid="generate-token-button"
                        type="submit"
                    >
                        Generate Access Token
                    </Button>
                </Flex>
            </form>
        </Box>
    )
}
