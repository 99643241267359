//
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import InstallStatus from './InstallStatus'
import ValidationStatus from './ValidationStatus'
import { logoStyle, shortCategory } from './utils'
import { getRailsRoutes } from 'utils/railsRoutes'
import { useHistory } from 'react-router'
import { selectCarrier } from 'reducers/modules/integrations'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'

export const RecommendedCarrier = ({
    carrier: { title, name, category, category_id, short_description },
    carrier,
}) => {
    const history = useHistory()
    const routes = getRailsRoutes()
    const { logos, landing_pages, frontend } = useSelector(
        ({
            app: { frontend, integrations: { logos, landing_pages } = {} } = {},
        }) => ({ landing_pages, frontend, logos })
    )
    const dispatch = useDispatch()

    const onNavigate = (e) => {
        e.preventDefault()
        if (frontend && landing_pages[name]) {
            window.open(landing_pages[name], '_self')
            return false
        }

        dispatch(selectCarrier(carrier))
        history.push(routes.ratesmgr_marketplace_path(name))
    }
    const url = routes.ratesmgr_marketplace_path(name)

    return (
        <a
            href={url}
            data-test-url={url}
            className={
                'app-1' +
                (logos && logos[name + 'ComingSoon'] ? ' coming-soon' : '')
            }
            onClick={onNavigate}
        >
            <div className={'logo ' + name} {...logoStyle(logos, name)}>
                <LogoImg src={logos ? logos[name] : null} alt={title} />
            </div>
            <div className="info">
                <div className="title">
                    <h4>{title}</h4>
                    <p className="type">{shortCategory(category)}</p>
                    <p className="desc">{short_description}</p>
                </div>
                {category_id !== 'other' && !frontend ? (
                    <div className="status">
                        <InstallStatus carrier={carrier} />
                        <ValidationStatus carrier={carrier} small={true} />
                    </div>
                ) : null}
            </div>
        </a>
    )
}

export default RecommendedCarrier
