//
import React, { useContext } from 'react'
import SearchContext from 'components/ui/SearchProvider'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

/**
 * LocationSelector Properties
 */

export default function LocationSelector(props) {
    const { onLocationChange } = props
    const context = useContext(SearchContext)
    const locations = useSelector(
        ({ app: { integrations: { locations = [] } = {} } = {} }) =>
            locations.map((item) => Object.entries(item)[0])
    )
    const location = useSelector(
        ({ app: { integrations: { location } = {} } = {} }) => location
    )

    const getFlagImage = (key) => {
        switch (key) {
            case 'US':
                return '🇺🇸'
            case 'GB':
                return '🇬🇧'
            case 'CA':
                return '🇨🇦'
            case 'AU':
                return '🇦🇺'
            case 'EU':
                return '🇪🇺'
            case 'ROW':
                return '🌐'
        }
    }

    return (
        <div>
            <Select
                name="region-select"
                onChange={(e) => onLocationChange(e, context)}
                defaultValue={props.location || (location ?? 'US')}
                data-testid="region-select"
                id="region-select"
            >
                {locations.map(([key]) => (
                    <option key={key} value={key}>
                        {getFlagImage(key)}
                    </option>
                ))}
            </Select>
        </div>
    )
}

const Select = styled.select`
    border: none;
    font-size: 24px;
`
