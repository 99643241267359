import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LogoImg from 'components/dashboard/integrations/components/LogoImg'
import { UninstallZonos } from './Uninstall'
import { InstallView } from './Install/InstallView'
import { InstallZonosModal } from './Install/Modal'
import { CrossBorderFeature } from './Install/CrossBorderFeature'
import { useIntegration } from '../../hooks/useInstallIntegration'
import { dataCache } from 'reducers/storeCache'

export function ZonosDetailsPage() {
    const [installModal, setInstallModal] = useState(false)
    const toggleModal = () => setInstallModal((prev) => !prev)

    const {
        data: { installed } = {},
        isLoading,
        refetch,
    } = useIntegration({ type: 'zonos', key: 'zonos' })

    const { enabled_features, selected_carrier = {} } = useSelector(
        (store) => ({
            enabled_features: store.app.enabled_features,
            ...store.app.integrations,
        })
    )

    const [enabledCrossBorder, setEnabledCrossBorder] = useState(
        enabled_features.includes('cross_border')
    )

    const refreshEnabledCrossBorder = () =>
        setEnabledCrossBorder(enabled_features.includes('cross_border'))

    const handleRefresh = () => {
        refetch()
        dataCache.flush()
    }

    return (
        <>
            <div className="integration-details max-cont-l">
                <div className="integration-details--content">
                    <div className="dt-txt">
                        <h2>Zonos</h2>
                        <hr />

                        {isLoading ? (
                            <div></div>
                        ) : installed ? (
                            <UninstallZonos
                                carrier={selected_carrier}
                                onUninstall={refetch}
                            />
                        ) : enabledCrossBorder ? (
                            <InstallView onContinue={toggleModal} />
                        ) : (
                            <CrossBorderFeature
                                onContinue={refreshEnabledCrossBorder}
                            />
                        )}
                    </div>
                </div>

                <div className="integration-details--picture">
                    <div className="dt-img mb-6">
                        <LogoImg src={selected_carrier.image} />
                    </div>

                    <div>
                        <p className="mb-4">
                            <strong>{selected_carrier.summary}</strong>
                        </p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: selected_carrier.description,
                            }}
                        />
                    </div>
                </div>
            </div>

            {installModal && (
                <InstallZonosModal
                    integration={selected_carrier}
                    onInstall={handleRefresh}
                    onClose={toggleModal}
                />
            )}
        </>
    )
}

export default ZonosDetailsPage
