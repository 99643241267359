import { useMutation, useQuery } from 'react-query'
import { axiosClient } from 'net/ajax'

const installIntegration =
    (type) =>
    (params = {}) => {
        return axiosClient.put('/ratesmgr/integrations/install', {
            integrations: { integration_type: type, ...params },
        })
    }

const unInstallIntegration = (type) => () => {
    return axiosClient.put('/ratesmgr/integrations/uninstall', {
        integrations: { integration_type: type },
    })
}

/**
 *
 * @param {number} type Integration type
 */
export default function useInstallIntegration(type) {
    const installProps = useMutation(installIntegration(type))

    const unInstallProps = useMutation(unInstallIntegration(type))

    const common = (name) => installProps[name] || unInstallProps[name]

    return {
        isSuccess: common('isSuccess'),
        isLoading: common('isLoading'),
        error: common('error'),
        isError: common('isError'),
        install: installProps.mutate,
        installAsync: installProps.mutateAsync,
        uninstall: unInstallProps.mutate,
        uninstallAsync: unInstallProps.mutateAsync,
    }
}

export const useIntegration = (variables, props = {}) => {
    const { type, key } = variables
    const { skip } = props

    const getIntegration = ({ type, key }) =>
        axiosClient
            .get(`/ratesmgr/integration?type=${type}&key=${key}`)
            .then(({ data }) => data)

    return useQuery(['integration'], () =>
        skip ? {} : getIntegration({ type, key })
    )
}
