import { useQuery } from 'react-query'
import { axiosClient } from 'net/ajax'

async function getStoreOrderPermission() {
    const response = await axiosClient.get('ratesmgr/user_settings/0')
    return response.data.store_quote_order
}

export function useGetStoreOrderPermission() {
    const {
        isLoading: isGettingStoreOrderPermission,
        data: canStoreQuoteOrder,
    } = useQuery('permissions', () => getStoreOrderPermission())

    return {
        isGettingStoreOrderPermission,
        canStoreQuoteOrder,
    }
}
