import React from 'react'
import styled from 'styled-components'
import { color, typography, space, layout } from 'styled-system'
import { Box, Heading, Button, Text, Hr, Flex, Link } from 'styles'
import useCopy from 'hooks/useCopy'

const FAIcon = styled.i`
    ${layout}
    ${space}
    ${color}
    ${typography}
`

export default function GenerateTokenResult({
    isSuccess,
    onOkClick,
    website,
    token,
}) {
    const { copied, copy } = useCopy(token)

    if (!isSuccess) {
        return <div>Error generating a token</div>
    }

    const handleCopyClick = () => copy()

    return (
        <Box px={12} textAlign="left">
            <Box textAlign="center">
                <FAIcon
                    color="green1"
                    className="fas fa-check-circle"
                    role="img"
                    aria-label="Check mark"
                    fontSize="24px"
                />
                <Heading mt={6} level="2">
                    ShipStation Installed Successfully
                </Heading>
            </Box>
            <Hr mb={12} />
            <Flex
                my={12}
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                width="100%"
                px={16}
                py={12}
                borderRadius="3px"
                className="info-msg"
            >
                <Text mb={8} fontWeight="bold" fontSize="1em">
                    Your Access Token for {website}
                </Text>
                <Flex justifyContent="center" alignItems="center">
                    <Text fontWeight="bold" mr={8}>
                        {token}
                    </Text>
                    <Button
                        data-testid="copy-token-button"
                        type="button"
                        disabled={copied}
                        className="btn btn-primary"
                        onClick={handleCopyClick}
                        px={6}
                        py={4}
                        minWidth="auto"
                    >
                        {copied ? 'Copied' : 'Copy'}
                        <FAIcon
                            ml={2}
                            className={`far fa-${copied ? 'check' : 'paste'}`}
                        />
                    </Button>
                </Flex>
            </Flex>
            <Box mb={8} textAlign="center">
                <Text>
                    Copy this token and enter it into your ShipStation account.
                </Text>
                <Text>
                    <Link
                        href="https://docs.shipperhq.com/how-to-connect-shipperhq-to-shipstation/"
                        target="_blank"
                    >
                        <i className="fal fa-info-circle" /> How to manage your
                        ShipStation Token.
                    </Link>
                </Text>
            </Box>
        </Box>
    )
}
