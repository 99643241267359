//
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchContext from 'components/ui/SearchProvider'
import { categories } from './categories'
import { changeMarketplaceLocation } from 'reducers/modules/integrations'
import { reloadAppData } from 'reducers/modules/app'
import { SearchBarComponent } from './SearchBarComponent'

/**
 * SearchBar Properties
 */

/**
 * SearchBar State
 */

/**
 */
export class SearchBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            status: props.query ? props.query.status : 'all',
            location: props.query ? props.query.location : 'all',
            query: '',
            category: props.query ? props.query.category : '',
            defaultCategory:
                props.query && props.query.category !== undefined
                    ? props.query.category
                    : 'all',
            categoryStats: {},
        }

        this.searchQuery = this.searchQuery.bind(this)
        this.changeCategory = this.changeCategory.bind(this)
        this.handleCategoryClick = this.handleCategoryClick.bind(this)
        this.selectTab = this.selectTab.bind(this)
        this.onLocationChange = this.onLocationChange.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        const { carrierCategories } = props

        return {
            ...state,
            categoryStats: props.availableCarriers
                ? Object.values(props.availableCarriers).reduce(
                      (acc, carrier) => {
                          /* $FlowFixMe */
                          const ids = [carrier.category_id]

                          /* $FlowFixMe */
                          const { name } = carrier
                          if (
                              carrierCategories &&
                              carrierCategories[name] &&
                              Array.isArray(carrierCategories[name])
                          ) {
                              ids.push(...carrierCategories[name])
                          }

                          for (const id of ids) {
                              if (acc[id]) {
                                  acc[id]++
                              } else {
                                  acc[id] = 1
                              }
                          }

                          return acc
                      },
                      {}
                  )
                : {},
        }
    }

    searchQuery(query, context) {
        this.setState({ query }, () => this.updateQuery(context))
    }

    changeCategory(e, context) {
        this.setState(
            {
                /* $FlowFixMe */
                category: e.target.value,
            },
            () => this.updateQuery(context)
        )
    }

    handleCategoryClick(id, context, title) {
        this.setState({ category: id }, () => this.updateQuery(context))
    }

    selectTab(tab, context) {
        this.setState(
            {
                status: tab.name,
            },
            () => this.updateQuery(context)
        )
    }

    updateQuery(context) {
        const { query, category, status, location } = this.state

        context.setSearchQuery({
            query,
            category,
            status,
            location,
        })
    }

    onStatusChange(e, context) {
        this.setState(
            {
                /* $FlowFixMe */
                status: e.target.value,
            },
            () => this.updateQuery(context)
        )
    }

    async onLocationChange(location, context) {
        this.setState({ location }, () => this.updateQuery(context))

        /* $FlowFixMe */
        await this.props.changeMarketplaceLocation(location)
        this.props.reloadAppData(false)
    }

    getAvailableCategories() {
        const { categoryStats } = this.state

        return categories.filter((category) => categoryStats[category.id] > 0)
    }

    render() {
        const { category, location } = this.state

        return (
            <SearchContext.Consumer>
                {(context) => (
                    <SearchBarComponent
                        category={category}
                        setCategory={this.handleCategoryClick}
                        setQuery={this.searchQuery}
                        query={context.searchQ?.query}
                        region={location}
                        setRegion={this.onLocationChange}
                        context={context}
                        categories={this.getAvailableCategories()}
                    />
                )}
            </SearchContext.Consumer>
        )
    }
}

export default connect(
    ({
        app: {
            frontend,
            logged_in,
            integrations: {
                recommended_carriers,
                available_integrations,
                carrier_categories = {},
            } = {},
        } = {},
    }) => ({
        frontend,
        loggedIn: logged_in,
        availableCarriers: {
            ...recommended_carriers,
            ...available_integrations,
        },
        carrierCategories: carrier_categories,
    }),
    {
        changeMarketplaceLocation,
        reloadAppData,
    }
)(SearchBar)
