import React, { useState, useEffect } from 'react'
import Modal from 'components/ui/Modal'
import { Box, Heading, Hr, Text } from 'styles'
import styled from 'styled-components'
import { color, layout, space, typography } from 'styled-system'
import { useUPSMyChoiceInstaller } from 'components/dashboard/integrations/services/UPSMyChoice/useUPSMyChoiceInstaller'

const FAIcon = styled.i`
    ${layout}
    ${space}
    ${color}
    ${typography}
`

function UPSMyChoiceInstallSuccess({ onClose }) {
    return (
        <>
            <Box textAlign="center">
                <FAIcon
                    color="green1"
                    className="fas fa-check-circle"
                    role="img"
                    aria-label="Check mark"
                />
                <Heading mt={6} level="2">
                    UPS My Choice&reg; was installed successfully
                </Heading>
            </Box>
            <Hr mb={12} />
            <Box mb={8}>
                <Heading fontWeight="bold" fontSize="1em" level="3">
                    What happens now?
                </Heading>
                <Text>
                    UPS My Choice&reg; was successfully enabled for your
                    ShipperHQ account. Please install Magento extension on your
                    store to enable this feature there.
                </Text>
            </Box>
            <button className="btn btn-primary" type="button" onClick={onClose}>
                Close
            </button>
        </>
    )
}

export function UPSMyChoiceIndexPageInstaller({ onClose }) {
    const [loading, setLoading] = useState(false)
    const { install } = useUPSMyChoiceInstaller()

    const doInstall = async () => {
        setLoading(true)
        try {
            await install()
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        doInstall()
    }, [])

    return (
        <Modal onClose={onClose}>
            {loading ? (
                <div>Installing...</div>
            ) : (
                <UPSMyChoiceInstallSuccess onClose={onClose} />
            )}
        </Modal>
    )
}
