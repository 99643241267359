import { isCarrierUPSMyChoice } from 'components/dashboard/integrations/services/utils'
import { useUPSMyChoiceInstallStatus } from 'components/dashboard/integrations/services/UPSMyChoice'

export function useServiceInstallStatus(carrier) {
    switch (true) {
        case isCarrierUPSMyChoice(carrier):
            return useUPSMyChoiceInstallStatus()
        default:
            return carrier?.installed
    }
}
